<script setup>
import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import Title from '@/Components/Title/Title.vue';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');
const props = defineProps({
    showing: Boolean,
    shift: Object,
    application: Object,
});

const emits = defineEmits(['update:showing']);

const closeForm = () => emits('update:showing', false);

const form = useForm({
    application_id: '',
});
const sendForm = () => {
    form.application_id = props.application.id;
    form.post(route('location.rejected-applications.store'), {
        onSuccess: closeForm,
    });
};


const absentForm = useForm({
    application_id: '',
});

const sendAbsent = () => {
    absentForm.application_id = props.application.id;
    absentForm.post(route('staff.absent-applications.store'), {
        onSuccess: closeForm,
    });
};

const illnessForm = useForm({
    application_id: '',
});

const sendIllness = () => {
    illnessForm.application_id = props.application.id;
    illnessForm.post(route('staff.illness-applications.store'), {
        onSuccess: closeForm,
    });
};

const noShowForm = useForm({
    application_id: '',
});

const sendNoShow = () => {
    noShowForm.application_id = props.application.id;
    noShowForm.post(route('staff.no-show-applications.store'), {
        onSuccess: closeForm,
    });
};

const possibleShiftRejectStatuses = ['open', 'filled'];
const possibleApplicationRejectStatuses = ['applied', 'invited', 'accepted', 'confirmation_required'];

const canRejectApplication =
    possibleApplicationRejectStatuses.includes(props.application.status) &&
    possibleShiftRejectStatuses.includes(props.shift.status);

</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <Title :title="$t('Change status')" />
            <p class='mt-3 text-base text-blue'>
                {{ $t('Change the status of this application') }}
            </p>
        </template>
        <template #button-bar>

            <Button v-if='canRejectApplication' :processing='form.processing' :recentSuccessful='form.recentSuccessful'
                    danger
                    @click='sendForm'>
                {{ $t('Reject') }}
            </Button>

            <Button :processing='absentForm.processing' blue fullWidth @click='sendAbsent'>
                {{ $t('Absente') }}
            </Button>

            <Button :processing='illnessForm.processing' fullWidth orange @click='sendIllness'>
                {{ $t('Illness') }}
            </Button>

            <Button :processing='noShowForm.processing' danger fullWidth @click='sendNoShow'>
                {{ $t('No show') }}
            </Button>

            <Button @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
